export default{
    data(){
        return{
            count:0
        }
    },
    methods:{
        prevclick:function(){
            if(this.page == 1){
                return
            }else{
                this.page--
                this.getNewsList();
            }
        },
        nextclick:function(){
            if(this.orderLists.length == this.count){
                return
            }
            this.page++;
            this.getNewsList();

        },
        currentchange:function(val){
            console.log(val)
            this.page = val;
            this.getNewsList();
        }
    }
}