<template>
  <div class="news-item cf">
    <img
      class="fl news-img"
      :src="url"
      alt=""
    />
    <div class="r-box fl">
      <p class="title">{{title}}</p>
      <p class="date">{{ctime}}</p>
      <p class="context">
        {{brief}}
      </p>
      <div class="more-btn" @click="toDetail">查看详情></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    brief:{
      type:String,
      default:'新闻标题'
    },
    title:{
      type:String,
      default:'简介'
    },
    ctime:{
      type:String,
      default:'2021-11-11'
    },
    url:{
      type:String,
      default:''
    },
    id:{
      type:Number,
      default:0
    }
  },
  methods: {
    toDetail: function () {
      this.$router.push({ path: "/news-detail", query: { id:this.id} });
    },
  },
};
</script>
<style scoped>
.news-item .news-img {
  width: 276px;
  height: 184px;
  margin-right: 18px;
}
.news-item .r-box {
  width: 880px;
}
.news-item .title {
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}
.news-item .date {
  color: #999;
  font-size: 14px;
  margin-bottom: 10px;
}
.news-item .context {
  color: #999999;
  font-size: 14px;
  height: 72px;
  line-height: 24px;
  overflow: hidden;
}
.news-item .more-btn {
  color: #007db8;
  font-size: 15px;
  margin-top: 14px;
  cursor: pointer;
}
</style>