<template>
  <div class="c-width news">
    <div class="news-list">
      <div class="item-box" v-for="item in newsList" :key="item.id">
        <newsItem :brief='item.brief' :url='item.url' :id='item.id' :title='item.title' :ctime='item.ctime'/>
      </div>
    </div>
     <el-pagination
        v-if="count > 5"
          background
        style="margin: 40px auto;width:200px"
        layout="prev, pager, next"
        :total="count"
        page-size='5'
        @current-change = "currentchange"
        @prev-click="prevclick"
        @next-click="nextclick"
      >
      </el-pagination>
  </div>
</template>
<script>
import mixins from "../mixins";
import newsItem from "@/views/news/component/news-item";
export default {
  mixins,
  components: {
    newsItem,
  },
};
</script>
<style scoped>
@import "../news.css";
</style>