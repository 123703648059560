export default{
    data:function(){
        return{
            newsList:[],
            page:1,

        }
    },
    created(){
        this.getNewsList();
    },
    methods: {
        getNewsList: function () {
            this.$post('/api/index/newsList.html', {
                page:this.page,
                pageSize:5
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.count = res.count
                    this.newsList  = res.data || []      
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        }
    },
}